import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map } from 'lodash/fp'

import { Layout } from 'src/components/Layout'

import { BodyCopy } from 'src/slices/BodyCopy'
import { PageTitle } from 'src/slices/PageTitle'
import { PhotoSlideshow } from 'src/slices/PhotoSlideshow'

const OurStoryPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle theme="teal">{get('frontmatter.title', page)}</PageTitle>
      <PhotoSlideshow pt={[0, null, null, 10]} pb={0}>
        {map(
          slide => (
            <PhotoSlideshow.Slide
              imageFluid={get('image.childImageSharp.fluid', slide)}
            />
          ),
          get('frontmatter.slides', page)
        )}
      </PhotoSlideshow>
      <BodyCopy
        heading={get('frontmatter.subtitle', page)}
        markdown={get('rawMarkdownBody', page)}
      />
    </Layout>
  )
}

export default OurStoryPage

export const query = graphql`
  query OurStoryPage {
    file(relativePath: { eq: "customPages/our-story.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subtitle
          slides {
            image {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        rawMarkdownBody
      }
    }
  }
`
