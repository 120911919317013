import React, { useEffect } from 'react'
import Carousel from 'nuka-carousel'
import { isBrowser } from 'helpers'

import {
  Box,
  Button,
  AspectRatio,
  GatsbyImage,
  GatsbyImageContainer,
  SVG,
} from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { IconChevronLeft } from 'src/components/IconChevronLeft'
import { IconChevronRight } from 'src/components/IconChevronRight'

const Slide = ({ children, imageFluid, ...props }) => (
  <AspectRatio x={16} y={9} position="relative" {...props}>
    <GatsbyImageContainer>
      <GatsbyImage fluid={imageFluid} alt="" />
    </GatsbyImageContainer>
  </AspectRatio>
)

const ControlButton = ({ svg, ...props }) => (
  <Button p={[2, null, 3]} cursor="pointer" {...props}>
    <SVG svg={svg} x={11} y={18} width={['0.75rem', '1rem']} fill="white" />
  </Button>
)

export const PhotoSlideshow = ({
  children,
  duration = 6000,
  transition = 1000,
  ...props
}) => {
  useEffect(() => {
    if (!isBrowser) return

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 25)
  }, [])

  return (
    <BoundedBox as="section" pr={0} pl={0} {...props}>
      <Box bg="black">
        <Carousel
          autoplay={true}
          autoplayInterval={duration}
          speed={transition}
          transitionMode="fade"
          wrapAround={true}
          renderBottomCenterControls={() => null}
          renderCenterLeftControls={({ previousSlide }) => (
            <ControlButton
              svg={IconChevronLeft}
              onClick={previousSlide}
              aria-label="Previous"
            />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ControlButton
              svg={IconChevronRight}
              onClick={nextSlide}
              aria-label="Next"
            />
          )}
        >
          {children}
        </Carousel>
      </Box>
    </BoundedBox>
  )
}

PhotoSlideshow.Slide = Slide
